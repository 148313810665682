<template>
    <div>
        Seller Links
    </div>
</template>

<script>
    export default {
        name: "SellerLinks",
        data() {
            return {

            }
        }
    }
</script>

<style scoped lang="scss">

</style>